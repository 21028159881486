
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "Preview",
  props: {
    hoveredEmoji: {
      type: Object as any,
      required: false,
    },
  },
  computed: {
    shortCode(): any {
      if (this.hoveredEmoji.name) return this.hoveredEmoji.name;
      else return this.hoveredEmoji.shortcodes?.[0];
    },
    name(): any {
      if (!this.hoveredEmoji.annotation) return null;
      return this.hoveredEmoji.annotation;
    },
    image(): any {
      const { el, id, gif } = this.hoveredEmoji;
      if (el) return el;
      const image = new Image();
      image.classList.add("emoji");

      image.src = `${process.env.VUE_APP_NERTIVIA_CDN}emojis/${id}.${
        gif ? "gif" : "png"
      }`;
      return image.outerHTML;
    },
  },
});
