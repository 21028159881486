
import { defineComponent } from "vue";

export default defineComponent({
  props: ["emoji", "addEmojiButton"],
  data() {
    return {
      customEmojiUrl: process.env.VUE_APP_NERTIVIA_CDN + "emojis/",
    };
  },
});
