
import { CustomEmojisModule } from "@/store/modules/customEmojis";
import emojiParser from "@/utils/emojiParser";
import { getRecentEmojis } from "@/utils/recentEmojiManager";
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "Tabs",
  emits: ["click"],

  props: {
    pos: {
      type: Object as PropType<{ x?: number; y?: number }>,
      required: false,
    },
  },
  data() {
    return {
      categories: ["😀", "🐱", "🍎", "🏀", "🚗", "⌚️", "❤️", "🏁"],
      tabLeftPos: null as string | null,
      tabShown: false,
    };
  },
  computed: {
    recentEmojis(): any {
      return getRecentEmojis();
    },
    customEmojis(): any {
      return CustomEmojisModule.customEmojis;
    },
  },
  methods: {
    findGroupEmojiPos(unicode: string) {
      return emojiParser.allEmojis.find((e) => e.unicode === unicode)?.pos;
    },
    tabsHover(event: any, index: number) {
      const toolTip: any = this.$refs.toolTip;
      if (index == 0) {
        toolTip.innerHTML = "Recents";
      }
      if (index == 1) {
        toolTip.innerHTML = "Custom Emojis";
      }
      if (index > 1) {
        toolTip.innerHTML = (emojiParser.allGroups as any)[index - 2];
      }
      this.tabShown = true;
      const tabLeftPos = event.target.offsetLeft;
      const toolTipCenter = (this.$refs.toolTip as any).clientWidth / 2;
      this.tabLeftPos = tabLeftPos - toolTipCenter + 17 + "px";
    },
    tabClicked(i: number | string) {
      if (typeof i === "number") {
        this.$emit("click", (emojiParser.allGroups as any)[i]);
        return;
      }
      this.$emit("click", i);
    },
    tabLeave() {
      this.tabShown = false;
    },
  },
});
